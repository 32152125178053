
import React, {memo} from 'react';

import styles from './index.module.scss';

const Laoder = () => {
    return (
        <div
            className={styles.loaderContainer}
        >
            <div className={styles.spinner}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};

export default memo(Laoder);
