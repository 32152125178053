import React, {memo} from 'react';

import {
    Border,
    Container,
    Heading,
    SubHeading,
    TextWrapper
} from '../../components';

import styles from './index.module.scss';
import ProductsFilter from './ProductsFilter';

const Products = () => {
    return (
        <Container
            id="projects"
            className={styles.containerMain}
        >
            <TextWrapper>
                <Heading>Проекты</Heading>
                <Border />
                <SubHeading>
          Главной задачей наших проектов, на протяжении многих лет было найти
          понимание и возможность удовлетворить желание заказчика сделать
          правильный выбор.
                </SubHeading>
                <SubHeading style={{marginBottom: '30px'}}>
          Это гарантирует качество нашей работы, которая заключается в том,
          чтобы превзойти ожидания наших клиентов. Объединяя основные принципы,
          мы постоянно развиваем новые перспективы, технологии, материалы и
          стили в работе.
                </SubHeading>
            </TextWrapper>
            <ProductsFilter />
        </Container>
    );
};

export default memo(Products);
