import React, {FC, memo} from 'react';
import {Pagination} from '@nextui-org/react';

import {ISwithcerData} from '../../pageContent/Products/ProductsFilter';

type TPaginationCard = {
  projectsLength: number;
  projectsFilter: ISwithcerData;
  changePage: (page: ISwithcerData) => void;
};

const PaginationCard: FC<TPaginationCard> = (props) => {
    return (
        <Pagination
            total={Math.ceil(props.projectsLength / 6)}
            initialPage={1}
            onChange={(page: number) =>
                props.changePage({
                    currentPage: props.projectsFilter.currentPage,
                    page
                })
            }
            page={props.projectsFilter.page}
            style={{marginTop: '30px', alignSelf: 'flex-start'}}
        />
    );
};

export default memo(PaginationCard);
