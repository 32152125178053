import React, { FC, memo, useState } from 'react';
import type { StaticImageData } from 'next/image';
import Lightbox from 'yet-another-react-lightbox';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';

import 'yet-another-react-lightbox/styles.css';

import Laoder from '../../../components/Laoder';
import galleryArray from '../../../mockData/ImageGallery';
import { TProjectsData } from '../../../mockData/Products';
import NextJsImage from '../../../modules/ImageGallery';
import InfoCard from '../../../modules/InfoCard';
import { useFilteredPageProjects } from '../../../utils/hooks';
import styles from '../index.module.scss';

type TCardsPart = {
  projectsShow: TProjectsData[];
  page: number;
  loading: boolean;
};

const CardsPart: FC<TCardsPart> = (props) => {
    const [open, setOpen] = useState(false);
    const [slides, setSlides] = useState<Array<StaticImageData>>([]);
    const openGallery = async (id: number, type: 'commercial' | 'private') => {
        const slide = await galleryArray[type][id];
        setSlides(slide);
        setOpen(true);
    };

    const [projects, loading] = useFilteredPageProjects(
        props.projectsShow,
        props.page
    );
    if (loading || props.loading) {
        return <Laoder />;
    }
    return (
        <div className={styles.cardContainer}>
            <div className={styles.container}>
                {projects.map((item, index) => (
                    <InfoCard
                        openGallery={openGallery}
                        index={index}
                        key={item.id}
                        {...item}
                    />
                ))}
            </div>
            <Lightbox
                open={open}
                close={() => setOpen(false)}
                slides={slides}
                render={{ slide: NextJsImage }}
                styles={{ container: { backgroundColor: 'rgba(0, 0, 0, 0.75)' } }}
                plugins={[Zoom]}
            />
        </div>
    );
};

export default memo(CardsPart);
