import styled from 'styled-components';

interface ISwitcherButton {
  index: number;
  currentPage: number;
}

export const IOSSwitcherWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: center;
  align-items: center;
  height: 44px; // 400 * 0.1
  border-radius: 12px;
  background-color: #EFEFEF;
  padding-inline: 5px;
`;

export const SwitcherButton = styled.div<ISwitcherButton>`
  height: 34px; // 400 * 0.085
  border-radius: 10px;
  background-color: ${(props) =>
        props.index === props.currentPage ? '#1062E5' : 'transparent'};
  align-items: center;
  justify-content: center;
  flex-direction: row;
  display: flex;
  margin-inline: 3px;
  transition: 0.3s;

  :hover {
    background-color: ${(props) =>
        props.index === props.currentPage ? '#1062E5' : '#ffffff'};
  }
  padding-inline: 10px;
  font-size: 1rem;
  font-weight: ${(props) =>
        props.index === props.currentPage ? '600' : '300'};
  color: ${(props) =>
        props.index === props.currentPage ? 'white' : 'black'};

  @media screen and (max-width: 600px) {
    font-size: 12px;
  }
`;
