import type { StaticImageData } from 'next/image';


export async function importImages(
    type: 'private' | 'commercial',
    folderPath: string,
    numberOfImages: number
): Promise<StaticImageData[]> {
    const images: StaticImageData[] = [];
    for (let i = 1; i <= numberOfImages; i++) {
        const imageModule = await import(
            `public/assets/${type}/${folderPath}/${i}File.webp`
        );
        images.push(imageModule.default);
    }
    return images;
}

export const privateArray = [
    importImages('private', '1Folder', 23),
    importImages('private', '2Folder', 8),
    importImages('private', '3Folder', 13),
    importImages('private', '4Folder', 14),
    importImages('private', '5Folder', 15),
    importImages('private', '6Folder', 8),
    importImages('private', '7Folder', 7),
    importImages('private', '8Folder', 15),
    importImages('private', '9Folder', 13),
    importImages('private', '10Folder', 12),
    importImages('private', '11Folder', 11),
    importImages('private', '10Folder', 12),
    importImages('private', '13Folder', 4),
    importImages('private', '14Folder', 9),
    importImages('private', '15Folder', 7),
    importImages('private', '16Folder', 19),
    importImages('private', '17Folder', 5),
    importImages('private', '18Folder', 9),
];

export const commercialArray = [
    importImages('commercial', '1Folder', 12),
    importImages('commercial', '2Folder', 12),
    importImages('commercial', '3Folder', 10),
    importImages('commercial', '4Folder', 11),
    importImages('commercial', '5Folder', 6),
    importImages('commercial', '6Folder', 5),
    importImages('commercial', '7Folder', 8),
    importImages('commercial', '8Folder', 4),
    importImages('commercial', '9Folder', 6),
    importImages('commercial', '10Folder', 14),
    importImages('commercial', '11Folder', 7),
];

type IGalleryArray = {
  commercial: Array<Promise<StaticImageData[]>>;
  private: Array<Promise<StaticImageData[]>>;
};
const galleryArray: IGalleryArray = {
    commercial: commercialArray,
    private: privateArray,
};

export default galleryArray;

