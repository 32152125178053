import React, {memo} from 'react';
import type {NextPage} from 'next';
import Image from 'next/image';

import {ButtonStandart} from '../../components/Button/ButtonElements';
import {TProjectsData} from '../../mockData/Products';
import {useWindowSize} from '../../utils/getWindow';

import styles from './index.module.scss';

interface IInfoCard extends TProjectsData {
  openGallery: (id: number, type: 'commercial' | 'private') => void;
  index: number;
}

const InfoCard: NextPage<IInfoCard> = (props) => {
    const {id, name, placeRus, meeters, sellType, index} = props;
    const {width} = useWindowSize();

    return (
        <div
            key={id}
            className={styles[`image${index}`]}
            onClick={() => width && width < 900 && props.openGallery(id, sellType)}
        >
            <div className={styles.imageContainer}>
                <div className={styles[`text${index}`]}>
                    <div className={styles.columnDiv}>
                        <h2 className={styles.descriptionName}>
                            {name}
                        </h2>
                        <h2 className={styles.descriptionRest}>
                            {placeRus}
                        </h2>
                        <h2 className={styles.descriptionRest}>
                            {meeters}
                        </h2>
                    </div>
                </div>
                <Image
                    src={`/assets/${sellType}/${id + 1}Folder/1.webp`}
                    alt={name}
                    quality={10}
                    unoptimized={true}
                    fill
                    className={styles['custom-img']}
                    loading="lazy"
                ></Image>
                <div className={styles[`button${index}`]}>
                    <div className={styles.rowDiv}>
                        <ButtonStandart
                            primarybtn="true"
                            onClick={() => props.openGallery(id, sellType)}
                        >
            Просмотреть больше
                        </ButtonStandart>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo(InfoCard);
