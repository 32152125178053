import React, {memo} from 'react';

import {data} from '../../mockData/Products';

import {IOSSwitcherWrapper, SwitcherButton} from './index.style';

interface IProps {
  currentPage: number;
  changeCurrentPage: (category: number) => void;
}

const IOSSwitcher: React.FC<IProps> = (props) => {

    return (
        <IOSSwitcherWrapper>
            {[...Array(data.length)].map((_, index) => (
                <SwitcherButton
                    key={index}
                    index={index}
                    currentPage={props.currentPage}
                    onClick={() => props.changeCurrentPage(index)}
                >
                    {data[index].title}
                </SwitcherButton>
            ))}
        </IOSSwitcherWrapper>
    );
};

export default memo(IOSSwitcher);
