/* eslint-disable sonarjs/no-duplicate-string */
export const data = [
    {
        _id: '1',
        title: 'Все проекты',
    },
    {
        _id: '2',
        title: 'Коммерческие',
    },
    {
        _id: '3',
        title: 'Частные',
    },
];

export type TProjectsData = {
  id: number;
  sellType: 'private' | 'commercial';
  placeRus?: string;
  meeters?: string;
  name: string;
};

export const projectsDataPrivate: Array<TProjectsData> = [
    {
        id: 0,
        sellType: 'private',
        meeters: '269 м2',
        placeRus: 'г. Минск',
        name: 'Частный дом в стиле Фьюжен',
    },
    {
        id: 1,
        sellType: 'private',
        meeters: '148,7 м2',
        name: 'Частный загородный дом в стиле шале',
    },
    {
        id: 2,
        sellType: 'private',
        meeters: '283,5 м2',
        placeRus: 'г. Бобруйск',
        name: 'Частный дом (Американская классика)',
    },
    {
        id: 3,
        sellType: 'private',
        meeters: '104 м2',
        placeRus: 'г. Минск',
        name: '3-х комнатная квартира',
    },
    {
        id: 4,
        sellType: 'private',
        meeters: '114,6 м2',
        placeRus: 'г. Минск',
        name: '4-х комнатная квартира',
    },
    {
        id: 5,
        sellType: 'private',
        meeters: '182,8 м2',
        placeRus: 'г. Москва',
        name: 'Частный бассейн в загородном доме',
    },
    {
        id: 6,
        sellType: 'private',
        meeters: '54,8 м2',
        placeRus: 'г. Минск',
        name: '2-х комнатная квартира',
    },
    {
        id: 7,
        sellType: 'private',
        meeters: '118 м2',
        placeRus: 'г. Бобруйск',
        name: '3-х комнатная квартира',
    },
    {
        id: 8,
        sellType: 'private',
        meeters: '145,6 м2',
        placeRus: 'г. Москва',
        name: 'Загородный дом в стиле Шалле',
    },
    {
        id: 9,
        sellType: 'private',
        meeters: '132 м2',
        name: 'Квартира в классическом стиле',
    },
    {
        id: 10,
        sellType: 'private',
        meeters: '53 м2',
        placeRus: 'г. Москва',
        name: 'Квартира',
    },
    {
        id: 11,
        sellType: 'private',
        meeters: '71,6 м2',
        placeRus: 'г. Москва',
        name: 'Квартира',
    },
    {
        id: 12,
        sellType: 'private',
        meeters: '25,3 м2',
        placeRus: 'г. Москва',
        name: 'Разработка летней терассы',
    },
    {
        id: 13,
        sellType: 'private',
        meeters: '74 м2',
        placeRus: 'г. Бобруйск',
        name: 'Квартира',
    },
    {
        id: 14,
        sellType: 'private',
        meeters: '92,4 м2',
        placeRus: 'г. Бобруйск',
        name: 'Частный дом',
    },
    {
        id: 15,
        sellType: 'private',
        meeters: '215 м2',
        name: 'Частный дом с элементами классики',
    },
    {
        id: 16,
        sellType: 'private',
        meeters: '84 м2',
        placeRus: 'г. Москва',
        name: 'Квартира',
    },
    {
        id: 17,
        sellType: 'private',
        meeters: '56,4 м2',
        placeRus: 'г. Минск',
        name: 'Квартира для молодой семьи',
    },
];
export const projectsDataCommercial: Array<TProjectsData> = [
    { id: 0, sellType: 'commercial', placeRus: 'г. Бобруйск', name: 'Коворкинг' },
    {
        id: 1,
        sellType: 'commercial',
        placeRus: 'г. Бобруйск',
        name: 'Физкультурно-оздоровительный комплекс',
    },
    { id: 2, sellType: 'commercial', placeRus: 'г. Бобруйск', name: 'Кафе' },
    {
        id: 3,
        sellType: 'commercial',
        placeRus: 'Чехия',
        name: 'Магазин льняного текстиля Ceske Budejovice',
    },
    {
        id: 4,
        sellType: 'commercial',
        placeRus: 'г. Бобруйск',
        name: 'Свадебный салон',
    },
    {
        id: 5,
        sellType: 'commercial',
        name: 'Экстерьерное решение зданий и прилегающей территории',
    },
    {
        id: 6,
        sellType: 'commercial',
        name: 'Кабинет руководителя и приемная предприятия',
    },
    {
        id: 7,
        sellType: 'commercial',
        placeRus: 'г. Бобруйск',
        name: 'Кондитерская кафе',
    },
    {
        id: 8,
        sellType: 'commercial',
        placeRus: 'г. Бобруйск',
        name: 'Оформление свадебного салона',
    },
    {
        id: 9,
        sellType: 'commercial',
        placeRus: 'г. Москва',
        name: 'Разработка и реализация офиса компани Навител',
    },
    {
        id: 10,
        sellType: 'commercial',
        placeRus: 'г. Бобруйск',
        name: 'Ресторанный комплекс Темьян в Киселевичах',
    },
];
