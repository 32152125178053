import { useEffect, useRef, useState } from 'react';
import _ from 'lodash/isEqual';

import { projectsDataCommercial, projectsDataPrivate, TProjectsData } from '../mockData/Products';

const usePrevious = (value: string) => {
    const ref = useRef('');
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

export const useFilteredCategoryProjects = (
    currentPage: number
): [Array<TProjectsData>, boolean] => {
    const [projects, setProjects] = useState<Array<TProjectsData>>([]);
    const [loading, setLoading] = useState(false);
    const jsonData = JSON.stringify({ currentPage });
    const myPreviousState = usePrevious(jsonData);
    const shuffleArray = projectsDataPrivate.concat(projectsDataCommercial);

    useEffect(() => {
        setLoading(true);
        const timeout = setTimeout(() => setLoading(false), 500);
        if (
            myPreviousState !== undefined &&
            !_(myPreviousState, jsonData)
        ) {
            switch (currentPage) {
                case 0:
                    return (setProjects(shuffleArray), () => clearTimeout(timeout));
                case 1:
                    return (
                        setProjects(projectsDataCommercial), () => clearTimeout(timeout)
                    );
                case 2:
                    return (setProjects(projectsDataPrivate), () => clearTimeout(timeout));
                default:
                    return (setProjects(shuffleArray), () => clearTimeout(timeout));
            }
        }
        return (setProjects(shuffleArray), () => clearTimeout(timeout));
    }, [currentPage]);

    return [projects, loading];
};

export const useFilteredPageProjects = (
    array: Array<TProjectsData>,
    page: number
): [Array<TProjectsData>, boolean] => {
    const [projects, setProjects] = useState<Array<TProjectsData>>([]);
    const [loading, setLoading] = useState(false);
    const jsonData = JSON.stringify({ page });
    const myPreviousState = usePrevious(jsonData);
    const shuffleArray = projectsDataPrivate.concat(projectsDataCommercial);

    useEffect(() => {
        setLoading(true);
        const timeout = setTimeout(() => setLoading(false), 500);

        if (myPreviousState !== undefined) {
            if (page === 1) {
                return (setProjects(array.slice(0, 6)), () => clearTimeout(timeout));
            } else if (page === projects.length / 6) {
                return (setProjects(array.slice(6 * page)), () => clearTimeout(timeout));
            } else {
                return (
                    setProjects(array.slice(6 * (page - 1), 6 * page)),
                    () => clearTimeout(timeout)
                );
            }
        }
        return (setProjects(shuffleArray), () => clearTimeout(timeout));
    }, [array, page]);

    return [projects, loading];
};