import React, {memo, useCallback, useState} from 'react';

import IOSSwitcher from '../../../modules/IOSSwitcher';
import PaginationCard from '../../../modules/Pagination';
import {useFilteredCategoryProjects} from '../../../utils/hooks';
import CardsPart from '../Cards';

export interface ISwithcerData {
  page: number;
  currentPage: number;
}

const ProductsFilter = () => {
    const [filter, setFilter] = useState<ISwithcerData>({
        page: 1,
        currentPage: 0
    });
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const Scroll = require('react-scroll');
    const scroller = Scroll.scroller;
    const Element = Scroll.Element;

    const changeCurrentPage = useCallback((category: number) => {
        setFilter({page: 1, currentPage: category});
    }, []);

    const changePage = useCallback((filter1: ISwithcerData) => {
        setFilter(filter1);
        scroller.scrollTo('projectsData', {
            smooth: true,
            duration: 500,
            spy: true,
            offset: -70
        });
    }, []);

    const [project1, loading] = useFilteredCategoryProjects(filter.currentPage);

    return (
        <>
            <Element name="projectsData">
                <IOSSwitcher
                    currentPage={filter.currentPage}
                    changeCurrentPage={changeCurrentPage}
                />
            </Element>
            <CardsPart projectsShow={project1} page={filter.page} loading={loading} />
            <PaginationCard
                projectsLength={project1.length}
                projectsFilter={filter}
                changePage={changePage}
            />
        </>
    );
};

export default memo(ProductsFilter);
